<template>
  <div class="survey">
    <div class="row">
      <member-list class="pt-3" :facets="coordinatorFacets" :query="coordinatorQuery" :fields="coordinatorFields"
                   :title="this.$t('labels.coordinator')"></member-list>
    </div>
  </div>
</template>
<script>
import MemberList from '@/components/lists/member/MemberList';

export default {
  name: 'history',
  props: {
    id: Number,
    localOffice: Object,
  },
  components: {
    MemberList,
  },
  computed: {
    coordinatorQuery() {
      return {
        query: {
          bool: {
            must: [
              {
                match: {
                  'office.raw': this.localOffice.office.label,
                },
              },
              {
                nested: {
                  path: 'coordinator',
                  query: {
                    exists: {
                      field: 'coordinator.label',
                    },
                  },
                },
              },
            ],
          },
        },
      };
    },
  },
  data() {
    return {
      coordinatorFacets: [
        {
          level: 1,
          id: 'officeFilter',
          field: 'office.raw',
          label: this.$t('labels.victim-support-centres'),
          placeholder: this.$t('labels.victim-support-centre'),
          type: 'list',
        },
        {
          level: 1,
          id: 'localofficeFilter',
          field: 'localoffice.raw',
          label: this.$t('labels.local-office'),
          placeholder: this.$t('labels.local-office'),
          type: 'list',
        },
        {
          level: 1,
          id: 'categoryFilter',
          field: 'category.raw',
          label: this.$t('labels.category'),
          placeholder: this.$t('labels.all-categories'),
          type: 'list',
          small: true,
        },
        {
          level: 1,
          id: 'nameFilter',
          field: 'full_name',
          label: this.$t('labels.name'),
          placeholder: `${this.$t('labels.name')}..`,
          type: 'search',
        },
        {
          level: 1,
          id: 'idFilter',
          field: 'id',
          label: this.$t('labels.member-number'),
          placeholder: `${this.$t('labels.member')} #..`,
          type: 'search',
        },
        {
          level: 2,
          id: 'genderSubFilter',
          field: 'gender.raw',
          label: this.$t('labels.gender'),
          placeholder: this.$t('labels.gender'),
          type: 'list',
          small: true,
        },
        {
          level: 2,
          id: 'coordinatorSubFilter',
          nestedField: 'coordinator',
          field: 'coordinator.label.raw',
          label: this.$t('labels.coordinator-type'),
          placeholder: this.$t('labels.coordinator-type'),
          type: 'list',
          small: true,
        },
        {
          level: 2,
          id: 'typeSubFilter',
          field: 'type.raw',
          label: this.$t('labels.non-profit-employee'),
          placeholder: this.$t('labels.non-profit-employee'),
          type: 'list',
          small: true,
        },
        {
          level: 2,
          id: 'supportSubFilter',
          nestedField: 'support',
          field: 'support.label.raw',
          label: this.$t('labels.support-type'),
          placeholder: this.$t('labels.support-type'),
          type: 'list',
          small: true,
        },
      ],
      coordinatorFields: [
        {
          key: 'id',
          sort_field: 'id',
          label: '#',
          width: 6,
        },
        {
          key: 'first_name',
          sort_field: 'first_name.raw',
          label: this.$t('labels.first-name'),
          width: 11,
        },
        {
          key: 'last_name',
          sort_field: 'last_name.raw',
          label: this.$t('labels.last-name'),
          width: 11,
        },
        {
          key: 'office',
          sort_field: 'office.raw',
          label: this.$t('labels.victim-support-centre'),
          width: 12,
        },
        {
          key: 'coordinator_list',
          sort_field: 'coordinator_list.raw',
          label: this.$t('labels.coordinator'),
          width: 14,
        },
        {
          key: 'type',
          sort_field: 'type.raw',
          label: this.$t('labels.non-profit-employee'),
          width: 14,
        },

        {
          key: 'mobile',
          sort_field: 'mobile.raw',
          label: this.$t('labels.mobile'),
          width: 12,
        },
        {
          key: 'email',
          sort_field: 'email.raw',
          label: '',
          width: 5,
        },
      ],
    };
  },
};
</script>
